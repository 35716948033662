
.banner {
    padding-left: 30px;
    width: 100%;
    background-image: url('../src/Images/background_image.jpg');
    /* background-color: black; */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.banner-left{
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 1;
}
.banner {
  background: linear-gradient(45deg, #FFDA30, #2CBDB7, #BDD632, #27DBB1, #FFDA30, #2CBDB7);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-size: 600% 100%;
    animation: gradient 5s infinite;
    animation-direction: alternate;
}

.pxl-image-wg {
  -webkit-animation: pxl-circle-move-animation 12s infinite linear;
    -moz-animation: pxl-circle-move-animation 12s infinite linear;
    -o-animation: pxl-circle-move-animation 12s infinite linear;
    animation: pxl-circle-move-animation 12s infinite linear;
    position: absolute;
    z-index: 99999;
    top: 15%;
    right: 5%;
}

@keyframes pxl-circle-move-animation {
  0% {
      transform: rotate(0)translate(-30px)rotate(0)
  }

  25% {
      transform: rotate(360deg)translate(-30px)rotate(-360deg)
  }

  50% {
      transform: rotate(720deg)translate(-30px)rotate(-720deg)
  }

  75% {
      transform: rotate(1080deg)translate(-30px)rotate(-1080deg)
  }

  100% {
      transform: rotate(1440deg)translate(-30px)rotate(-1440deg)
  }
}


@keyframes gradient {
  0% {
      background-position: 0%
  }

  100% {
      background-position: 100%
  }
}

.background-line {
    background-image: url("Images/background_image.jpg");
    opacity: 1;
    mix-blend-mode: multiply;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

@keyframes color {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
.sub-title{
  font-family: "poppins";
    font-size: 20px !important;
    text-align: left;
    color: #fff;
}
.banner_title{
    font-family: "Montserrat";
    font-size: 44px !important;
    font-weight: 900;
    text-align: left;
    line-height: 60px;
    color: #fff;
}
.line::before{
    content: " ";
    width: 100px;
    height: 8px;
    background-color: #fff;
    display: inline-block;
}
.banner_para{
    font-family: "poppins";
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    /* width: 400px; */
    color: #fff;
}
.banner_btn{
    border: 2px solid #fff;
    padding: 15px 35px;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 600;
    word-spacing: 4px;
    letter-spacing: 2px;
    color: #fff;
    background-color: transparent;
    transition: all .3s;
}

.banner_btn:hover {
  background-color: #000;
}
/* .follow::before{
    content: " ";
    width: 45px;
    height: 2px;
    margin-right: 25px;
    background-color: blue;
    display: inline-block;
} */





.bubble-container{
    position:relative;
    width:100%;
    height: 100%;
    z-index:1;
    overflow:hidden;
    top:0;
    left:0;
  }
  .bubble{
    position: absolute;
    bottom:-100px;
    width:100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
   
    border-radius:50%;
    opacity:1;
    animation: rise 10s infinite ease-in;
  }
  .bubble:nth-child(1){
    /* width:40px;
    height:40px; */
    left:10%;
    animation-duration:8s;
  }
  .bubble:nth-child(2){
    /* width:20px;
    height:20px; */
    left:20%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(3){
    /* width:50px;
    height:50px; */
    left:35%;
    animation-duration:7s;
    animation-delay:2s;
  }
  .bubble:nth-child(4){
    /* width:80px;
    height:80px; */
    left:50%;
    animation-duration:11s;
    animation-delay:0s;
  }
  .bubble:nth-child(5){
    /* width:35px;
    height:35px; */
    left:55%;
    animation-duration:6s;
    animation-delay:1s;
  }
  .bubble:nth-child(6){
    /* width:45px;
    height:45px; */
    left:65%;
    animation-duration:8s;
    animation-delay:3s;
  }
  /* .bubble:nth-child(7){
    left:70%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .bubble:nth-child(8){
    left:80%;
    animation-duration:6s;
    animation-delay:2s;
  }
  .bubble:nth-child(9){
    left:70%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(10){
    left:25%;
    animation-duration:10s;
    animation-delay:4s;
  } */
  @keyframes rise{
    0%{
      bottom:-100px;
      transform:translateX(0);
    }
    50%{
      transform:translate(100px);
    }
    100%{
      bottom:1080px;
      transform:translateX(-200px);
    }
  }


  @media only screen and  (max-width: 991px) {
    .pxl-image-wg {
      display: none;
    }
    
  } 
 
  @media only screen and (min-width: 992px) and (max-width: 1075px) {
    .pxl-image-wg {
      right: -10%;
    }
    .banner {
      overflow-x: hidden;
    }
  }

  .navbar-toggler {
    background-color: #000;
  }