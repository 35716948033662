
/* .title-section{
    text-align: center;
}
.title-section-main{
    font-family: "Montserrat", Sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
}
.title-section-desc{
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.text-box{
    box-shadow: 0 0 10px #ccc;
    padding: 25px;
    margin-bottom: 30px;
}
.text-box-icon{
    font-size: 35px;
    padding-bottom: 20px;
    color: #25BBBC;
}
.text-box-title{
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    padding-bottom: 10px;
}
.text-box-desc p{
    font-size: 15px;
}
.text-box-bule{
    background: #25BBBC none repeat scroll 0 0;
    overflow: hidden;
    color: #ffffff;
}
.text-box-bule .text-box-icon{
    color: #ffffff;
}


@media (max-width:767px){
    .title-section-main{
        font-size: 25px;
    }
    .title-section-desc{
        max-width: 100%;
    }
    .text-box-bule{
        background: #ffffff none repeat scroll 0 0;
        color: #444;
    }
    .text-box-bule .text-box-icon{
        color: #42a4ff;
    }
    
}
@media (max-width:768px) and (max-width:1023px){
    .text-box-bule{
        background: #ffffff none repeat scroll 0 0;
        color: #444;
    }
    .text-box-bule .text-box-icon{
        color: #42a4ff;
    }
} */


/*why choose us*/

h1,
h2,
h3,
h4,
h5,
h6
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}
a {
font-style: normal;
text-decoration: none;
transition: all 500ms ease;
-moz-transition: all 500ms ease;
-webkit-transition: all 500ms ease;
-ms-transition: all 500ms ease;
-o-transition: all 500ms ease;
}
a,
a:active,
a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}

.chooseus-section{
position: relative;
background: #fff;
padding: 50px 0px;
min-height: 100vh;

}

.chooseus-section .sec-title{
margin-bottom: 60px;
}
.title-style{
    color: #FFDA30;
}
#video_block_01 .video-inner{
background-image: url(../../Images/why-choose-us.png);
position: relative;
width: 100%;
padding: 175px 0px 130px 0px;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
border-radius: 10px;
box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

#video_block_01 .video-inner .pattern-layer{
/* background-image:url(https://i.ibb.co/1qq5bKr/cube-shapes.png); */
position: absolute;
left: 0px;
top: 0px;
width: 100%;
height: 100%;
background-repeat: no-repeat;
}

#video_block_01 .video-inner .video-btn{
position: relative;
display: inline-block;
width: 116px;
height: 115px;
line-height: 115px;
text-align: center;
margin-left: 40px;
border-radius: 50%;
}

#video_block_01 .video-inner .video-btn .btn-bg{
position: absolute;
left: 0px;
top: 0px;
width: 116px;
height: 115px;
background-repeat: no-repeat;
}

#video_block_01 .video-inner .video-btn a{
position: relative;
display: inline-block;
width: 116px;
height: 115px;
border-radius: 50%;
font-size: 24px;
z-index: 1;
}
#video_block_01 .video-inner .video-btn a i {
background: -webkit-linear-gradient(-45deg, #00bb6e, #0156d5 100%);
}
#video_block_01 .video-inner .video-btn a i{
position: relative;
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

#content_block_04 .content-box .single-item{
position: relative;
}

#content_block_04 .content-box .single-item:first-child{
padding-left: 190px;
margin-bottom: 67px;
}

#content_block_04 .content-box .single-item:last-child{
padding-right: 190px;
}

#content_block_04 .content-box .single-item .icon-box{
position: absolute;
/* top: 14px; */
width: 158px;
height: 158px;
line-height: 158px;
text-align: center;
font-size: 70px;
border: 1px solid #e5e5e5;
border-radius: 10px;
}

#content_block_04 .content-box .single-item .icon-box:before{
position: absolute;
content: '';
background: #fbfbfb;
width: 138px;
height: 100%;
left: 10px;
border-radius: 10px;
}

#content_block_04 .content-box .single-item:first-child .icon-box:before{
top: 10px;
}

#content_block_04 .content-box .single-item:last-child .icon-box:before{
top: -10px;
}

#content_block_04 .content-box .single-item .icon-box i {
background: -webkit-linear-gradient(-45deg, #FFDA30, #25BCBD 100%);
position: relative;
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

#content_block_04 .content-box .single-item .icon-box .bg-layer{
position: absolute;
left: 0px;
top: 0px;
width: 158px;
height: 158px;
background-repeat: no-repeat;
}

#content_block_04 .content-box .single-item:first-child .icon-box{
left: 0px;
}

#content_block_04 .content-box .single-item:last-child .icon-box{
right: 0px;
}

#content_block_04 .content-box .single-item .box h4{
position: relative;
padding-bottom: 12px;
margin-bottom: 27px;
}

#content_block_04 .content-box .single-item .box h4:before{
position: absolute;
content: '';
width: 55px;
height: 2px;
bottom: 0px;
}

#content_block_04 .content-box .single-item:first-child .box h4:before{
left: 0px;
}

#content_block_04 .content-box .single-item:last-child .box h4:before{
right: 0px;
}

#content_block_04 .content-box .single-item .box p{
margin-bottom: 20px;
line-height: 28px;
}

#content_block_04 .content-box .single-item .box a{
position: relative;
display: inline-block;
font-size: 16px;
line-height: 26px;
font-family: 'Josefin Sans', Sans-serif;
font-weight: 600;
color: #040535;
}

#content_block_04 .content-box .single-item .box a:hover{
color: #5167f9;
}

#content_block_04 .content-box .single-item:first-child .box a i{
margin-right: 10px;
}

#content_block_04 .content-box .single-item:last-child .box a i{
margin-left: 10px;
}
.rotate-me {
    background-image: url(https://i.ibb.co/kKmvKRY/btn-icon-bg.png);
animation-name: rotateme;
animation-duration: 30s;
animation-iteration-count: infinite;
animation-timing-function: linear;
-webkit-animation-name: rotateme;
-webkit-animation-duration: 30s;
-webkit-animation-iteration-count: infinite;
-webkit-animation-timing-function: linear;
-moz-animation-name: rotateme;
-moz-animation-duration: 30s;
-moz-animation-iteration-count: infinite;
-moz-animation-timing-function: linear;
-ms-animation-name: rotateme;
-ms-animation-duration: 30s;
-ms-animation-iteration-count: infinite;
-ms-animation-timing-function: linear;
-o-animation-name: rotateme;
-o-animation-duration: 30s;
-o-animation-iteration-count: infinite;
-o-animation-timing-function: linear;
}


@keyframes rotateme {
from {
transform: rotate(0deg);
}
to { 
transform: rotate(-360deg);
}
}
@-webkit-keyframes rotateme {
from {
-webkit-transform: rotate(0deg);
}
to { 
-webkit-transform: rotate(-360deg);
}
}
@-moz-keyframes rotateme {
from {
-moz-transform: rotate(0deg);
}
to { 
-moz-transform: rotate(-360deg);
}
}
@-o-keyframes rotateme {
from {
-o-transform: rotate(0deg);
}
to { 
-o-transform: rotate(-360deg);
}
}
.modal-body {
padding-bottom: 56.25%;
position: relative;
height: 0;
padding-top: 0;
overflow: hidden;
}

.modal-body iframe, .modal-body object, .modal-body embed, .modal-body video {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

button.close {
position: absolute;
right: -20px;
top: -30px;
border-radius: 50px;
background: white;
line-height: 1 !important;
padding: 0px 6px 5px !important;
opacity: 1;
}
.why-choose-title{
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
}
.why-choose-title-color{
    color: #FFDA30;
}
#content_block_04 .content-box .single-item .box h4 {
    font-family: "poppins";
    font-size: 25px;
    margin-bottom: 10px;
}
#content_block_04 .content-box .single-item .box p{
    font-family: "poppins";
    font-size: 16px;
}