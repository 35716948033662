
.tesimonial-section{
  background-color: #FFFFFF;
  padding: 50px 0 50px 0;
}
.testi-title-section{
  text-align: center;
}
.testi-title-section-main{
  font-family: "Montserrat", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}
.testi-title-section-desc{
  font-family: "poppins";
  font-size: 16px;
  line-height: 25px;
}

  .carousal-box{
    width: 100%;
    height: 200px;
    background-color: #FAFAFA;
    box-shadow: 0 0 10px #eceaea;
    border-radius: 10px !important;
    border-radius: 5px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    
  }
  .carousel-indicators{
    bottom: -50px;
  }
  .carousel-indicators [data-bs-target]{
    background-color: #FFD43B;
  }
 .clr-img{
    width: 60px;
    height: 60px;
    border-radius: 30px;
 }
 .testi-name{
  font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    color: blue;
 }
.testi-comment{
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  color: black
}
.testi-rating{
  font-size: 16px;
  color: #FFD43B;
  margin-top: -10px;

  
}
.fa-star{
  padding-left: 5px;
}




/* @keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.carousel-inner {
  display: flex;
  overflow: hidden; 
}

.carousel-item {
  flex: 0 0 auto;
  animation: scroll 5s linear infinite; 
  margin-right: 20px; 
} */

