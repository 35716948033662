.logo{
    width: 170px;
    height: 60px;
}
.navbar-brand{
    margin-right: 0px;
    text-align: center;
}
.navbar{
    padding-top:15px ;
    padding-bottom:15px ;
}
.topbar{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: black;
}
.topbar-link{
    color: white;
    font-family: "poppins";
    font-size: 15px !important; 
}
.topbar-icon{
    font-size: 20px;
    color:white;
    margin-left: 20px;
}
.navbar-nav .topbar-link:hover,
.navbar-nav .topbar-icon:hover{
    color: white !important;
}
.nav-item{
    padding-left: 25px;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
    color: #FFDA30 !important;
    border-bottom: 3px solid #FFDA30; 
}

.nav-link{
    font-family: "Montserrat", Sans-serif;
    /* font-family: "poppins"; */
    font-size: 14px !important;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    color: black;
}
