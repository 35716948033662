
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

ul {
  list-style: none;
}

input {
  overflow: hidden;
}

.section-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0 0 35px;
}

/* .sec-pad {
  padding: 60px 0 0;
  margin: 50px 0;
} */
.contact{
  /* border: 2px solid black; */
  border-radius: 10px;
  padding: 20px;
  background-color: white;
}
.contact-sec {
  /* align-item: center; */
  display: flex;
  background-color: #64d486;
}

.contact-sec .contact-ul li,
.contact-ul b {
  font-size: 20px;
  margin: 10px 0;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  word-wrap: break-word;
}

.contact-sec .contact-ul i {
  font-size: 18px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.contact-detail a {
  color: #000;
  text-decoration: none;
}

.contact-sec .contact-ul li b:hover {
  color: #f93;
}

.contact-sec .contact-ul li .fa-location-dot {
  color: #f44337;
  border: 2px solid #f4433790;
}

.contact-sec .contact-ul li .fa-phone {
  color: #00b055;
  border: 2px solid #00b05590;
}

.contact-sec .contact-ul li .fa-envelope {
  color: #ff6347;
  border: 2px solid #ff634790;
}

.contact-detail span {
  width: 400px;
  display: flex;
  justify-content: center;
}
.contact-detail span a {
  font-size: 20px;
  padding: 6px 12px;
  color: #000;
  border-radius: 50%;
  margin: 0px 5px;
}
.contact-detail span .fb {
  color: #3b5998;
  border: 3px solid #3b5998;
}
.contact-detail span .fb:hover {
  color: #fff;
  background-color: #3b5998;
}

.contact-detail span .insta {
  color: #833ab4;
  border: 3px solid #833ab4;
}
.contact-detail span .insta:hover {
  color: #fff;
  background-color: #833ab4;
}

.contact-detail span .twitter {
  color: #00acee;
  border: 3px solid #00acee;
}
.contact-detail span .twitter:hover {
  color: #fff;
  background-color: #00acee;
}

/* form.contFrm {
  max-width: 396px;
  margin: auto;
} */
.contFrm{
  font-family: 'poppins';
}
.contact h2,.timeline h2{
  
  font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
}
.contact p,.timeline p{
  
  font-family: "poppins";
    font-size: 16px;
    
}
.timeline-content h3{
  
  font-family: "poppins";
    font-size: 25px;
    
}
.timeline-content p{
  
  font-family: "poppins";
    font-size: 15px;
    padding-bottom: 10px;
    
}

.inptFld {
  width: 100%;
  height: 50px;
  border: 1px solid #d9d9d9;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 0 20px;
  font-size: 16px;
  color: #000;
  margin-top: 5px;
}

.inptFld:focus {
  outline-offset: -4px;
  outline: 1px solid #f93;
}

.contFrm textarea {
  height: 75px;
  padding-top: 5px;
}
.inptBtn {
  height: 50px;
  border: 0;
  border-radius: 5px;
  background: #25BCBD;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin: auto;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}

/* Responcive css Start */

@media (max-width: 991px) {
  .sec-pad {
    padding: 20px 0 0px;
  }

  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 18px;
  }

  .contact-sec .contact-ul i {
    font-size: 14px;
    padding: 6px;
    margin-right: 6px;
  }

  .inptFld {
    height: 40px;
    margin: 0 0 10px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact-detail span {
    width: auto;
  }
  .contact-detail span a {
    font-size: 18px;
    padding: 5px 10px;
    color: #000;
    border-radius: 50%;
    margin: 0px 5px 20px;
  }
}

@media (max-width: 575px) {
  .section-title {
    font-size: 26px;
    font-weight: 500;
  }
  .contact-sec {
    border-radius: 10% 10% 0% 0% / 5% 5% 0% 0%;
  }

  .contact-sec .contact-ul i {
    border: none;
  }
  .inptFld {
    height: 36px;
    margin: 0 0 8px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 16px;
  }
}

/*timeline-area*/
.timeline{
  padding-left: 40px;
}
.timeline-area{
  margin: 50px auto;
  border-left:2px solid #000000 ;
  padding: 0 20px 0 30px;
}
.timeline-content{
  /* background-color: yellow; */
  padding: 0px 15px;
  font-size: 16px;
  /* border: 1px solid #000; */
  line-height: 1.7;
  position: relative;
  height: 110px;
  margin-bottom: 30px;
}
.timeline-content:before{
content: "";
display: block;
width: 30px;
height: 30px;
border-radius: 50%;
background-color: #fff;
border: 2px solid #000;
position: absolute;
top: 5px;
left: -46px;
}
/* .timeline-content:after{
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
  border-width: 8px;
  position: absolute;
  top: 10px;
  left: -17px;
  } */
  
  